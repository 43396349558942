import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';

function App() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch('https://fierce-springs-42328.herokuapp.com/endpoint1')
      .then(res => res.json())
      .then(data => setData(data.response));
  }, []);




  return (
    <div className="App">
      {data}
    </div>
  );
}

export default App;
